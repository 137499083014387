import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { supabase } from '../supabase/config';
import { Line, Doughnut } from 'react-chartjs-2';
import Card from '../components/Card';
import { ArrowUpIcon, ArrowDownIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';

// Colors for categories
const categoryColors = {
  'Auto & transport': '#22c55e',       // green-500
  'Gifts & donations': '#f97316',      // orange-500
  'Kids': '#a855f7',                   // purple-500
  'Home & renovation': '#3b82f6',      // blue-500
  'Food & dining': '#ec4899',          // pink-500
  'Fees & charges': '#eab308',         // yellow-500
  'Fitness & beauty': '#06b6d4',       // cyan-500
  'Entertainment': '#8b5cf6',          // violet-500
  'Groceries & household': '#ef4444',  // red-500
  'Retail shopping': '#10b981',        // emerald-500
  'Travel': '#d946ef',                 // fuchsia-500
  'Business expenses': '#64748b',      // slate-500
  'Subscription': '#94a3b8',           // slate-400
  'Bills & utilities': '#14b8a6',      // teal-500
  'Taxes': '#f59e0b',                  // amber-500
  'Uncategorised': '#6b7280',          // gray-500
  'Health & medical': '#e11d48',       // rose-600
  'Education': '#7c3aed'               // violet-600
};

// Register chart components
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

// Set Chart.js defaults
ChartJS.defaults.color = '#fff';
ChartJS.defaults.borderColor = 'transparent';

const Dashboard = ({ currentTheme }) => {
  const { user } = useAuth();
  const [monthlySpend, setMonthlySpend] = useState([]);
  const [remainingCredit, setRemainingCredit] = useState(15000);
  const [creditCardData, setCreditCardData] = useState({
    number: '**** **** **** ****',
    name: '',
    expiry: 'MM/YY',
    cvc: '***'
  });
  const creditLimit = 15000;
  const budgetLimit = 11000;
  const [categorySpend, setCategorySpend] = useState([]);
  const [selectedYear, setSelectedYear] = useState('all');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryTransactions, setCategoryTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
  const [debugMessage, setDebugMessage] = useState('Initial render');

  useEffect(() => {
    setDebugMessage('Effect running');
  }, []);

  const handleRemainingCreditChange = async (event) => {
    const newRemainingCredit = parseFloat(event.target.value) || 0;
    setRemainingCredit(newRemainingCredit);

    try {
      const { error } = await supabase
        .from('shared_credit')
        .update({ 
          remaining_amount: newRemainingCredit,
          updated_at: new Date().toISOString()
        })
        .eq('id', 1);

      if (error) throw error;
    } catch (error) {
      console.error('Error updating remaining credit:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;

      try {
        setDebugMessage('Fetching data...');

        // Fetch category spend data with ignore filter
        const { data: categoryData, error: categoryError } = await supabase
          .rpc('get_category_spend_by_year');

        if (categoryError) throw categoryError;

        if (categoryData?.length) {
          setCategorySpend(categoryData);
        }

        // Fetch monthly spend data
        const { data: monthlySpendData, error: monthlySpendError } = await supabase
          .from('monthly_spend')
          .select('*')
          .order('date', { ascending: true });

        if (monthlySpendError) throw monthlySpendError;

        const formattedMonthlySpend = monthlySpendData.map(record => ({
          ...record,
          date: new Date(record.date)
        }));
        setMonthlySpend(formattedMonthlySpend);

        // Fetch remaining credit
        const { data: creditData, error: creditError } = await supabase
          .from('shared_credit')
          .select('remaining_amount')
          .eq('id', 1)
          .single();

        if (creditError && creditError.code !== 'PGRST116') {
          console.error('Error fetching credit data:', creditError);
          return;
        }

        if (creditData) {
          setRemainingCredit(creditData.remaining_amount);
        }

      } catch (error) {
        console.error('Error in fetchData:', error);
        setDebugMessage('Error fetching data');
      }
    };

    fetchData();

    const subscription = supabase
      .channel('shared_credit_changes')
      .on(
        'postgres_changes',
        (payload) => {
          setRemainingCredit(payload.new.remaining_amount);
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [user]);

  const currentSpend = remainingCredit ? (creditLimit - remainingCredit) : 0;
  const budgetUsagePercent = Math.min(((currentSpend || 0) / budgetLimit) * 100, 100);

  const formatCurrency = (amount) => {
    return `$${(amount || 0).toLocaleString()}`;
  };

  const maskCardNumber = (number) => {
    if (!number) return '**** **** **** ****';
    const last4 = number.slice(-4);
    return `**** **** **** ${last4}`;
  };

  const formatExpiry = (date) => {
    if (!date) return 'MM/YY';
    const [year, month] = date.split('-');
    return `${month}/${year.slice(-2)}`;
  };

  const metrics = getCurrentMonthMetrics();

  function getCurrentMonthMetrics() {
    if (!monthlySpend.length) return null;

    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    const lastYear = currentYear - 1;
    
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const daysElapsed = now.getDate();

    const currentSpend = creditLimit - remainingCredit;
    const dailyRate = currentSpend / daysElapsed;
    const projectedSpend = Math.round(dailyRate * daysInMonth);
    const budgetDailyRate = budgetLimit / daysInMonth;
    
    // Calculate expected spend by current day
    const expectedSpendToDate = budgetDailyRate * daysElapsed;
    const spendDifference = currentSpend - expectedSpendToDate;

    const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const prevMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    const previousMonthSpend = monthlySpend.find(
      record => record.date.getMonth() === prevMonth && 
                record.date.getFullYear() === prevMonthYear
    );

    const lastYearSpend = monthlySpend.find(
      record => record.date.getMonth() === currentMonth && 
                record.date.getFullYear() === lastYear
    );

    const currentMonthSpend = monthlySpend.find(
      record => record.date.getMonth() === currentMonth && 
                record.date.getFullYear() === currentYear
    );

    return {
      current: projectedSpend,
      previous: previousMonthSpend?.actual || 0,
      lastYear: lastYearSpend?.actual || 0,
      budget: budgetLimit,
      daysElapsed,
      daysInMonth,
      currentSpend,
      dailyRate: Math.round(dailyRate),
      budgetDailyRate: Math.round(budgetDailyRate),
      expectedSpendToDate: Math.round(expectedSpendToDate),
      spendDifference: Math.round(spendDifference)
    };
  }

  const chartData = prepareChartData();
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'linear',
        min: 0,
        max: 11,
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: { 
          color: '#9CA3AF',
          callback: function(value) {
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            return months[value];
          }
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: '#9CA3AF',
          callback: function(value) {
            return formatCurrency(value);
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#9CA3AF',
          usePointStyle: true,
          padding: 20,
          font: {
            size: 12
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(17, 24, 39, 0.8)',
        titleColor: '#F3F4F6',
        bodyColor: '#D1D5DB',
        padding: 12,
        borderColor: 'rgba(255, 255, 255, 0.1)',
        borderWidth: 1,
        callbacks: {
          label: function(context) {
            return `${context.dataset.label}: ${formatCurrency(context.parsed.y)}`;
          }
        }
      }
    }
  };

  function prepareChartData() {
    if (!monthlySpend.length) return null;

    const yearGroups = monthlySpend.reduce((acc, record) => {
      const year = record.date.getFullYear();
      if (!acc[year]) acc[year] = [];
      acc[year].push(record);
      return acc;
    }, {});

    const currentYear = new Date().getFullYear();
    const datasets = Object.entries(yearGroups).map(([year, records]) => ({
      label: `${year} Spend`,
      data: records.map(record => ({
        x: record.date.getMonth(),
        y: record.actual
      })),
      borderColor: `hsl(${parseInt(year) * 60 % 360}, 70%, 60%)`,
      backgroundColor: `hsla(${parseInt(year) * 60 % 360}, 70%, 60%, 0.1)`,
      tension: 0.4,
      fill: true
    }));

    const currentYearData = yearGroups[currentYear];
    if (currentYearData) {
      datasets.unshift({
        label: 'Budget Target',
        data: currentYearData.map(record => ({
          x: record.date.getMonth(),
          y: record.budget
        })),
        borderColor: '#9333EA',
        backgroundColor: 'rgba(147, 51, 234, 0.1)',
        tension: 0.4,
        fill: true,
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 4,
        order: -1
      });
    }

    return { datasets };
  }

  // Fetch category transactions when category is selected
  useEffect(() => {
    const fetchCategoryTransactions = async () => {
      if (!selectedCategory) return;

      try {
        let query = supabase
        .from('credit_card_spend')
        .select('*')
        .eq('our_category', selectedCategory.name).eq('ignore', false);

        if (selectedYear !== 'all') {
          const yearStart = `${selectedYear}-01-01`;
          const yearEnd = `${selectedYear}-12-31`;
          query = query.gte('date', yearStart).lte('date', yearEnd);
        }

        const { data, error } = await query;
        if (error) throw error;

        setCategoryTransactions(data);
      } catch (error) {
        console.error('Error fetching category transactions:', error);
      }
    };

    fetchCategoryTransactions();
  }, [selectedCategory, selectedYear]);

  // Sort function for table
  const sortData = (key) => {
    setSortConfig((prevConfig) => {
      const direction = 
        prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc';
      return { key, direction };
    });
  };

  // Filter and sort transactions
  const getFilteredAndSortedTransactions = () => {
    let filtered = categoryTransactions;
    
    if (searchTerm) {
      filtered = filtered.filter(transaction => 
        transaction.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (sortConfig.key) {
      filtered.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return filtered;
  };

  // Update getDonutChartData to sort categories by amount
  const getDonutChartData = () => {
    if (!categorySpend || categorySpend.length === 0) {
      return null;
    }

    let filteredData = categorySpend;
    if (selectedYear !== 'all') {
      filteredData = categorySpend.filter(item => item.year === parseInt(selectedYear));
      if (filteredData.length === 0) {
        return null;
      }
    }

    const categoryTotals = {};
    filteredData.forEach(item => {
      if (!item.our_category) {
        return;
      }
      const category = item.our_category.trim() || 'Uncategorised';
      const spend = Math.abs(parseFloat(item.total_spend) || 0);
      categoryTotals[category] = (categoryTotals[category] || 0) + spend;
    });

    if (Object.keys(categoryTotals).length === 0) {
      return null;
    }

    // Sort categories by amount
    const sortedEntries = Object.entries(categoryTotals)
      .sort(([, a], [, b]) => b - a);

    const sortedCategories = sortedEntries.map(([category]) => category);
    const spendValues = sortedEntries.map(([, amount]) => Math.round(amount));
    const total = spendValues.reduce((sum, value) => sum + value, 0);
    
    if (total === 0) {
      return null;
    }

    const percentages = spendValues.map(value => ((value / total) * 100).toFixed(1));

    const chartData = {
      labels: sortedCategories,
      datasets: [{
        data: spendValues,
        backgroundColor: sortedCategories.map(cat => categoryColors[cat] || '#6b7280'),
        borderWidth: 0
      }]
    };

    return {
      chartData,
      total: Math.round(total),
      categoryDetails: sortedCategories.map((category, index) => ({
        name: category,
        amount: spendValues[index],
        percentage: percentages[index],
        color: categoryColors[category] || '#6b7280'
      }))
    };
  };

  // Line chart options
  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'linear',
        min: 0,
        max: 11,
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: { 
          color: '#9CA3AF',
          callback: function(value) {
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            return months[value];
          }
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: '#9CA3AF',
          callback: function(value) {
            return formatCurrency(value);
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#9CA3AF',
          usePointStyle: true,
          padding: 20,
          font: {
            size: 12
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(17, 24, 39, 0.8)',
        titleColor: '#F3F4F6',
        bodyColor: '#D1D5DB',
        padding: 12,
        borderColor: 'rgba(255, 255, 255, 0.1)',
        borderWidth: 1,
        callbacks: {
          label: function(context) {
            return `${context.dataset.label}: ${formatCurrency(context.parsed.y)}`;
          }
        }
      }
    }
  };

  // Donut chart options
  const donutChartOptions = {
    responsive: true,
    cutout: '75%',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function(context) {
            const value = context.raw;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${context.label}: $${value.toLocaleString()} (${percentage}%)`;
          }
        }
      }
    }
  };

  const donutData = getDonutChartData();
  const years = Array.from(new Set(categorySpend.map(item => item.year))).sort((a, b) => b - a);

  return (
    <div className="container mx-auto px-4 py-8">

      <div className="space-y-6">
        {/* Credit Card Section */}
        <Card currentTheme={currentTheme}>
          <div className="space-y-6">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
              <div>
                <h2 className="text-2xl font-bold mb-1">Credit Card</h2>
                <p className="text-gray-400">Track your credit card usage and remaining balance</p>
              </div>
              <div className="relative">
                <input
                  type="number"
                  value={remainingCredit}
                  onChange={handleRemainingCreditChange}
                  className="w-full md:w-48 bg-navy-700/50 border border-navy-600/50 rounded-xl py-2 px-8 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  placeholder="Enter remaining credit"
                />
                <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none text-gray-400">
                  $
                </div>
              </div>
            </div>
            
            {/* Progress Bar */}
            <div className="space-y-2">
              <div className="flex justify-between items-center text-sm text-gray-400">
                <span>Current Spend</span>
                <span className="text-xl font-semibold text-white">{formatCurrency(currentSpend)}</span>
              </div>
              <div className="h-3 bg-navy-700/50 rounded-full overflow-hidden">
                <div
                  style={{ width: `${budgetUsagePercent}%` }}
                  className={`h-full rounded-full transition-all duration-500 ${
                    budgetUsagePercent > 90 
                      ? 'bg-red-500' 
                      : budgetUsagePercent > 75 
                        ? 'bg-yellow-500' 
                        : currentTheme?.buttonBg?.split(' ')[0] || 'bg-purple-500'
                  }`}
                />
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-400">Budget Limit: {formatCurrency(budgetLimit)}</span>
                <span className="text-gray-400">{budgetUsagePercent.toFixed(1)}% used</span>
              </div>
            </div>
          </div>
        </Card>

        {/* Key Metrics */}
        {metrics && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Projected Spend */}
            <Card currentTheme={currentTheme}>
              <h3 className="text-lg font-medium text-gray-200 mb-2">Projected Spend</h3>
              <div className="text-3xl font-bold text-white mb-4">
                {formatCurrency(metrics.current)}
              </div>
              <div className={`flex items-center ${metrics.current > metrics.previous ? 'text-red-400' : 'text-green-400'}`}>
                {metrics.current > metrics.previous ? (
                  <ArrowUpIcon className="w-4 h-4 mr-1" />
                ) : (
                  <ArrowDownIcon className="w-4 h-4 mr-1" />
                )}
                <span className="text-sm">
                  {Math.abs(((metrics.current - metrics.previous) / metrics.previous * 100).toFixed(1))}% vs last month
                </span>
              </div>
            </Card>

            {/* Daily Rate */}
            <Card currentTheme={currentTheme}>
              <h3 className="text-lg font-medium text-gray-200 mb-2">Daily Rate</h3>
              <div className="text-3xl font-bold text-white mb-4">
                {formatCurrency(metrics.dailyRate)}
              </div>
              <div className="space-y-2">
                <div className={`flex items-center ${
                  metrics.dailyRate > metrics.budgetDailyRate ? 'text-red-400' : 'text-green-400'
                }`}>
                  {metrics.dailyRate > metrics.budgetDailyRate ? (
                    <ArrowUpIcon className="w-4 h-4 mr-1" />
                  ) : (
                    <ArrowDownIcon className="w-4 h-4 mr-1" />
                  )}
                  <span className="text-sm">
                    {Math.abs(((metrics.dailyRate - metrics.budgetDailyRate) / metrics.budgetDailyRate * 100).toFixed(1))}% vs budget rate (${metrics.budgetDailyRate})
                  </span>
                </div>
                
                {/* Add spend progress comparison */}
                <div className={`flex items-center ${
                  metrics.spendDifference > 0 ? 'text-red-400' : 'text-green-400'
                }`}>
                  {metrics.spendDifference > 0 ? (
                    <ArrowUpIcon className="w-4 h-4 mr-1" />
                  ) : (
                    <ArrowDownIcon className="w-4 h-4 mr-1" />
                  )}
                  <span className="text-sm">
                    {Math.abs(metrics.spendDifference) > 0 ? (
                      `${formatCurrency(Math.abs(metrics.spendDifference))} ${metrics.spendDifference > 0 ? 'over' : 'under'} budget by day ${metrics.daysElapsed}`
                    ) : (
                      'On budget'
                    )}
                  </span>
                </div>
                <div className="text-xs text-gray-400">
                  Expected: {formatCurrency(metrics.expectedSpendToDate)} by day {metrics.daysElapsed}
                </div>
              </div>
            </Card>

            {/* Year Over Year */}
            <Card currentTheme={currentTheme}>
              <h3 className="text-lg font-medium text-gray-200 mb-2">vs. Last Year</h3>
              <div className="text-3xl font-bold text-white mb-4">
                {formatCurrency(metrics.current - metrics.lastYear)}
              </div>
              <div className={`flex items-center ${metrics.current > metrics.lastYear ? 'text-red-400' : 'text-green-400'}`}>
                {metrics.current > metrics.lastYear ? (
                  <ArrowUpIcon className="w-4 h-4 mr-1" />
                ) : (
                  <ArrowDownIcon className="w-4 h-4 mr-1" />
                )}
                <span className="text-sm">
                  {Math.abs(((metrics.current - metrics.lastYear) / metrics.lastYear * 100).toFixed(1))}% change
                </span>
              </div>
            </Card>
          </div>
        )}

        {/* Year Filter Chips */}
        <div className="flex flex-wrap gap-2 mb-6">
          <button
            onClick={() => setSelectedYear('all')}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
              selectedYear === 'all'
                ? currentTheme?.buttonBg || 'bg-purple-500'
                : 'bg-navy-700 hover:bg-navy-600'
            }`}
          >
            All Time
          </button>
          {years.map(year => (
            <button
              key={year}
              onClick={() => setSelectedYear(year.toString())}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                selectedYear === year.toString()
                  ? currentTheme?.buttonBg || 'bg-purple-500'
                  : 'bg-navy-700 hover:bg-navy-600'
              }`}
            >
              {year}
            </button>
          ))}
        </div>

        {/* Spend Distribution Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Distribution Chart */}
          <div className="bg-navy-800 rounded-xl p-6">
            <h3 className="text-xl font-semibold text-gray-200 mb-6">Spend Distribution</h3>
            {donutData && donutData.chartData && (
              <div className="relative flex items-center justify-center" style={{ height: '300px' }}>
                <Doughnut 
                  data={donutData.chartData} 
                  options={donutChartOptions}
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="text-center">
                    <div className="text-2xl font-bold">
                      ${donutData.total.toLocaleString()}
                    </div>
                    <div className="text-sm text-gray-400">Total Spend</div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Category Breakdown */}
          <div className="bg-navy-800 rounded-xl p-6">
            <h3 className="text-xl font-semibold text-gray-200 mb-6">Category Breakdown</h3>
            <div className="space-y-3">
              {donutData && donutData.categoryDetails && donutData.categoryDetails.map(category => (
                <div 
                  key={category.name} 
                  onClick={() => setSelectedCategory(category)}
                  className="bg-navy-900/50 border border-navy-700/50 rounded-lg p-4 cursor-pointer hover:bg-navy-800/50 transition-colors"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3">
                      <div 
                        className="w-4 h-4 rounded" 
                        style={{ backgroundColor: category.color }}
                      />
                      <span className="font-medium">{category.name}</span>
                    </div>
                    <div className="text-right">
                      <div className="font-semibold">${category.amount.toLocaleString()}</div>
                      <div className="text-sm text-gray-400">{category.percentage}% of spend</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Category Details Modal */}
        {selectedCategory && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4 overflow-y-auto">
            <div className="bg-navy-800 rounded-xl w-full max-w-4xl max-h-[90vh] flex flex-col my-auto">
              <div className="p-6 border-b border-navy-700">
                <div className="flex justify-between items-center">
                  <h3 className="text-xl font-semibold flex items-center gap-3">
                    <div 
                      className="w-4 h-4 rounded" 
                      style={{ backgroundColor: selectedCategory.color }}
                    />
                    {selectedCategory.name}
                  </h3>
                  <button 
                    onClick={() => setSelectedCategory(null)}
                    className="text-gray-400 hover:text-white"
                  >
                    <XMarkIcon className="w-6 h-6" />
                  </button>
                </div>

                {/* Search Box */}
                <div className="relative mt-6">
                  <input
                    type="text"
                    placeholder="Search descriptions..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full bg-navy-900 border border-navy-700 rounded-lg py-2 px-4 pl-10 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  />
                  <MagnifyingGlassIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                </div>
              </div>

              {/* Table Container */}
              <div className="flex-1 overflow-hidden">
                <div className="overflow-y-auto" style={{ maxHeight: 'calc(90vh - 180px)' }}>
                  <table className="w-full">
                    <thead className="bg-navy-900 sticky top-0 z-10">
                      <tr>
                        <th 
                          className="px-4 py-3 text-left cursor-pointer hover:bg-navy-700"
                          onClick={() => sortData('date')}
                        >
                          Date {sortConfig.key === 'date' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th 
                          className="px-4 py-3 text-left cursor-pointer hover:bg-navy-700"
                          onClick={() => sortData('description')}
                        >
                          Description {sortConfig.key === 'description' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th 
                          className="px-4 py-3 text-right cursor-pointer hover:bg-navy-700"
                          onClick={() => sortData('price')}
                        >
                          Amount {sortConfig.key === 'price' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {getFilteredAndSortedTransactions().map((transaction, index) => (
                        <tr 
                          key={index}
                          className="border-t border-navy-700 hover:bg-navy-700/30"
                        >
                          <td className="px-4 py-3">{new Date(transaction.date).toLocaleDateString()}</td>
                          <td className="px-4 py-3">{transaction.description}</td>
                          <td className="px-4 py-3 text-right">${Math.abs(transaction.price).toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Chart Section */}
        {chartData && (
          <Card currentTheme={currentTheme}>
            <h3 className="text-lg font-medium text-gray-200 mb-4">Spending Trends</h3>
            <div className="h-[300px] md:h-[400px]">
              <Line data={chartData} options={chartOptions} />
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Dashboard; 