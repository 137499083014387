import React from 'react';

function Card({ children, title, currentTheme }) {
  return (
    <div className="bg-navy-800/80 rounded-3xl shadow-xl p-8 text-white relative overflow-hidden border border-navy-700/50">
      <div className="relative z-10">
        {title && (
          <h2 className="text-2xl font-bold mb-6">{title}</h2>
        )}
        {children}
      </div>
    </div>
  );
}

export default Card; 