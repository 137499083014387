import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { supabase } from '../supabase/config';
import Card from '../components/Card';
import FileUpload from '../components/FileUpload';

const themes = [
  {
    name: 'Purple',
    id: 'purple',
    preview: 'bg-gradient-to-r from-purple-600 via-purple-900 to-black'
  },
  {
    name: 'Green',
    id: 'green',
    preview: 'bg-gradient-to-r from-green-600 via-green-900 to-black'
  },
  {
    name: 'Blue',
    id: 'blue',
    preview: 'bg-gradient-to-r from-blue-600 via-blue-900 to-black'
  },
  {
    name: 'Sky',
    id: 'sky',
    preview: 'bg-gradient-to-r from-sky-600 via-sky-900 to-black'
  }
];

function Profile() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [selectedTheme, setSelectedTheme] = React.useState('purple');

  React.useEffect(() => {
    const fetchUserTheme = async () => {
      try {
        const { data, error } = await supabase
          .from('user_preferences')
          .select('theme')
          .eq('user_id', user.id)
          .single();

        if (error) throw error;
        if (data) {
          setSelectedTheme(data.theme);
        }
      } catch (err) {
        console.error('Error fetching user theme:', err);
      }
    };

    fetchUserTheme();
  }, [user.id]);

  const handleThemeChange = async (themeId) => {
    try {
      const { error } = await supabase
        .from('user_preferences')
        .upsert({
          user_id: user.id,
          theme: themeId,
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'user_id'
        });

      if (error) throw error;

      setSelectedTheme(themeId);
      window.dispatchEvent(new CustomEvent('themeChange', { detail: themeId }));
    } catch (err) {
      console.error('Error updating theme:', err);
    }
  };

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="space-y-6">
        {/* Theme Selection */}
        <Card>
          <h2 className="text-2xl font-bold mb-6">Theme Settings</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {themes.map((theme) => (
              <div 
                key={theme.id}
                className={`
                  relative rounded-2xl overflow-hidden cursor-pointer
                  transition-transform hover:scale-105
                  ${selectedTheme === theme.id ? 'ring-2 ring-white ring-offset-4 ring-offset-[#1e293b]' : ''}
                `}
                onClick={() => handleThemeChange(theme.id)}
              >
                <div className={`h-32 ${theme.preview}`}></div>
                <div className="absolute bottom-0 left-0 right-0 p-4 bg-black/50 backdrop-blur-sm">
                  <div className="flex items-center justify-between">
                    <span className="font-medium">{theme.name}</span>
                    {selectedTheme === theme.id && (
                      <svg 
                        className="w-5 h-5 text-white" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M5 13l4 4L19 7" 
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Card>

        {/* File Upload */}
        <Card>
          <h2 className="text-2xl font-bold mb-6">Upload Files</h2>
          <div className="space-y-4">
            <FileUpload type="credit" />
            <FileUpload type="monthly" />
            <FileUpload type="tech" />
          </div>
        </Card>

        {/* Logout */}
        <Card>
          <button
            onClick={handleLogout}
            className="w-full px-6 py-3 bg-red-500/10 text-red-500 rounded-xl hover:bg-red-500/20 transition-colors focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-[#1e293b] flex items-center justify-center space-x-2"
          >
            <svg 
              className="w-5 h-5" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
              />
            </svg>
            <span>Logout</span>
          </button>
        </Card>
      </div>
    </div>
  );
}

export default Profile; 