import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { supabase } from '../supabase/config';

function Navbar({ currentTheme }) {
  const { user } = useAuth();
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  if (!user) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 pb-[env(safe-area-inset-bottom)] h-16">
      <nav className="bg-[#2a2a4a] relative h-full">
        {/* Background cut-out effect */}
        <div 
          className="absolute top-0 left-0 w-full h-full overflow-hidden"
          style={{
            background: 'radial-gradient(circle at var(--x) -8px, transparent 0, transparent 50px, #2a2a4a 50px)',
            '--x': `${
              isActive('/') ? '10%' : 
              isActive('/tech') ? '30%' : 
              isActive('/investments') ? '50%' : 
              isActive('/countdown') ? '70%' :
              isActive('/profile') ? '90%' : '10%'
            }`,
          }}
        />

        {/* Active Tab Floating Button */}
        <div 
          className="absolute -top-5 transition-all duration-300 ease-in-out"
          style={{
            left: `${
              isActive('/') ? '10%' : 
              isActive('/tech') ? '30%' : 
              isActive('/investments') ? '50%' : 
              isActive('/countdown') ? '70%' :
              isActive('/profile') ? '90%' : '10%'
            }`,
            transform: 'translateX(-50%)'
          }}
        >
          <div className="w-14 h-14 bg-white rounded-full shadow-lg flex items-center justify-center">
            <div className="w-12 h-12 bg-purple-500 rounded-full flex items-center justify-center text-white">
              {isActive('/') && (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                </svg>
              )}
              {isActive('/tech') && (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
              )}
              {isActive('/investments') && (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                </svg>
              )}
              {isActive('/countdown') && (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              )}
              {isActive('/profile') && (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-around h-16 relative z-10">
          <Link
            to="/"
            className={`flex flex-col items-center justify-center w-full h-full text-white transition-all duration-300 ${
              isActive('/') ? 'opacity-0' : 'opacity-70'
            }`}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg>
            <span className="text-xs mt-1">Home</span>
          </Link>

          <Link
            to="/tech"
            className={`flex flex-col items-center justify-center w-full h-full text-white transition-all duration-300 ${
              isActive('/tech') ? 'opacity-0' : 'opacity-70'
            }`}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            <span className="text-xs mt-1">Tech</span>
          </Link>

          <Link
            to="/investments"
            className={`flex flex-col items-center justify-center w-full h-full text-white transition-all duration-300 ${
              isActive('/investments') ? 'opacity-0' : 'opacity-70'
            }`}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
            </svg>
            <span className="text-xs mt-1">Invest</span>
          </Link>

          <Link
            to="/countdown"
            className={`flex flex-col items-center justify-center w-full h-full text-white transition-all duration-300 ${
              isActive('/countdown') ? 'opacity-0' : 'opacity-70'
            }`}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span className="text-xs mt-1">Events</span>
          </Link>

          <Link
            to="/profile"
            className={`flex flex-col items-center justify-center w-full h-full text-white transition-all duration-300 ${
              isActive('/profile') ? 'opacity-0' : 'opacity-70'
            }`}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
            <span className="text-xs mt-1">Profile</span>
          </Link>
        </div>
      </nav>
    </div>
  );
}

export default Navbar; 