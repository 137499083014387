import React, { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { supabase } from '../supabase/config';
import Papa from 'papaparse';

const parseDateString = (dateStr) => {
  if (!dateStr) return null;
  
  try {
    const [day, month, year] = dateStr.split('-');
    const monthMap = {
      'jan': 0, 'feb': 1, 'mar': 2, 'apr': 3, 'may': 4, 'jun': 5,
      'jul': 6, 'aug': 7, 'sep': 8, 'oct': 9, 'nov': 10, 'dec': 11
    };
    
    const monthNum = monthMap[month.toLowerCase()];
    if (monthNum === undefined) {
      throw new Error(`Invalid month: ${month}`);
    }
    
    const fullYear = 2000 + parseInt(year, 10);
    const date = new Date(fullYear, monthNum, parseInt(day, 10));
    
    if (isNaN(date.getTime())) {
      throw new Error(`Invalid date: ${dateStr}`);
    }
    
    return date.toISOString();
  } catch (err) {
    throw new Error(`Invalid date format. Expected d-mmm-yy (e.g., 1-Jan-23), got: ${dateStr}`);
  }
};

const parseAmount = (amount) => {
  if (!amount) return 0;
  const cleanAmount = amount.toString().replace(/[\$,\s]/g, '');
  const number = parseFloat(cleanAmount);
  
  if (isNaN(number)) {
    throw new Error(`Invalid amount: ${amount}`);
  }
  
  return number;
};

function FileUpload({ type, currentTheme }) {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleCreditCardUpload = async (file) => {
    try {
      // Parse CSV data
      const transactions = await new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          complete: (results) => resolve(results.data),
          error: (error) => reject(error)
        });
      });

      // Prepare transactions for Supabase
      const formattedTransactions = transactions
        .filter(transaction => transaction.Date && transaction.Price) // Filter out empty rows
        .map(transaction => {
          try {
            const date = parseDateString(transaction.Date);
            
            return {
              user_id: user.id,
              date: date,
              description: transaction.Description || '',
              account: transaction.Account || '',
              category: transaction.Category || '',
              price: parseAmount(transaction.Price),
              our_category: transaction['Our Category'] || '',
              sub_category: transaction['Sub Category'] || '',
              ignore: transaction.Ignore?.toLowerCase() === 'y' || transaction.Ignore === 'TRUE' || transaction.Ignore === 'true' || false,
            };
          } catch (err) {
            console.error('Error processing transaction:', transaction, err);
            throw new Error(`Error processing transaction dated ${transaction.Date}: ${err.message}`);
          }
        });

      if (formattedTransactions.length === 0) {
        throw new Error('No valid transactions found in the CSV file');
      }

      // First, delete all existing records
      const { error: deleteError } = await supabase
        .from('credit_card_spend')
        .delete()
        .or('ignore.eq.true,ignore.eq.false');

      if (deleteError) {
        console.error('Error deleting existing records:', deleteError);
        throw new Error('Failed to delete existing records');
      }

      // Wait a moment to ensure deletion is complete
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Then insert new records
      const { error: insertError } = await supabase
        .from('credit_card_spend')
        .insert(formattedTransactions);

      if (insertError) {
        console.error('Error inserting new records:', insertError);
        throw new Error('Failed to insert new records');
      }

      setSuccess(`Credit card transactions imported successfully (${formattedTransactions.length} records)`);
    } catch (err) {
      throw new Error('Error importing transactions: ' + err.message);
    }
  };

  const handleMonthlySpendUpload = async (file) => {
    try {
      const monthlyData = await new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          complete: (results) => resolve(results.data),
          error: (error) => reject(error)
        });
      });

      const formattedRecords = monthlyData
        .filter(record => record.Date && record.Budget && record.Actual)
        .map(record => {
          try {
            const date = record.Date;
            
            if (!date) {
              throw new Error('Date is required');
            }

            const budget = parseAmount(record.Budget);
            const actual = parseAmount(record.Actual);

            return {
              user_id: user.id,
              date: date,
              budget: budget,
              actual: actual
            };
          } catch (err) {
            console.error('Error processing monthly record:', record, err);
            throw new Error(`Error processing record dated ${record.Date}: ${err.message}`);
          }
        });

      if (formattedRecords.length === 0) {
        throw new Error('No valid records found in the CSV file');
      }

      // Delete all existing records for the user
      const { error: deleteError } = await supabase
        .from('monthly_spend')
        .delete()
        .eq('user_id', user.id);

      if (deleteError) throw deleteError;

      // Insert new records
      const { error: insertError } = await supabase
        .from('monthly_spend')
        .insert(formattedRecords);

      if (insertError) throw insertError;

      setSuccess(`Monthly spend data imported successfully (${formattedRecords.length} records)`);
    } catch (err) {
      throw new Error('Error importing monthly spend: ' + err.message);
    }
  };

  const handleTechUpload = async (file) => {
    try {
      const techData = await new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          complete: (results) => resolve(results.data),
          error: (error) => reject(error)
        });
      });

      const formattedRecords = techData
        .filter(record => record.Date && record.Item)
        .map(record => {
          try {
            const date = parseDateString(record.Date);
            
            if (!date) {
              throw new Error('Date is required');
            }

            return {
              user_id: user.id,
              purchase_date: date,
              supplier: record.Supplier || '',
              item: record.Item || '',
              cost: parseAmount(record.Cost || 0),
              source: record.Source || '',
              claimed: record.Claimed?.toLowerCase() === 'true' || false,
              claim_date: record.ClaimDate ? parseDateString(record.ClaimDate) : null,
              claim_discount: parseAmount(record.ClaimDiscount || 0)
            };
          } catch (err) {
            console.error('Error processing tech record:', record, err);
            throw new Error(`Error processing record dated ${record.Date}: ${err.message}`);
          }
        });

      if (formattedRecords.length === 0) {
        throw new Error('No valid records found in the CSV file');
      }

      const { error } = await supabase
        .from('tech_purchases')
        .insert(formattedRecords);

      if (error) throw error;

      setSuccess(`Tech purchases imported successfully (${formattedRecords.length} records)`);
    } catch (err) {
      throw new Error('Error importing tech purchases: ' + err.message);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
      setError('Please upload a CSV file');
      return;
    }

    setLoading(true);
    setError('');
    setSuccess('');

    try {
      switch (type) {
        case 'credit':
          await handleCreditCardUpload(file);
          break;
        case 'monthly':
          await handleMonthlySpendUpload(file);
          break;
        case 'tech':
          await handleTechUpload(file);
          break;
        default:
          throw new Error('Invalid upload type');
      }

      // Clear the file input
      event.target.value = '';
    } catch (err) {
      console.error('Error uploading file:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getTitle = () => {
    switch (type) {
      case 'credit':
        return 'Credit Card Transactions';
      case 'monthly':
        return 'Monthly Spend';
      case 'tech':
        return 'Tech Purchases';
      default:
        return '';
    }
  };

  return (
    <div className="p-4 bg-navy-700/30 rounded-xl border border-navy-600/30">
      <h3 className="font-semibold mb-2">{getTitle()}</h3>
      <input
        type="file"
        accept=".csv"
        onChange={handleFileUpload}
        className="block w-full text-sm text-gray-400
          file:mr-4 file:py-2 file:px-4
          file:rounded-full file:border-0
          file:text-sm file:font-semibold
          file:bg-purple-500 file:text-white
          hover:file:bg-purple-600
          file:cursor-pointer"
        disabled={loading}
      />
      {error && (
        <p className="mt-2 text-sm text-red-400">{error}</p>
      )}
      {success && (
        <p className="mt-2 text-sm text-green-400">{success}</p>
      )}
      {loading && (
        <p className="mt-2 text-sm text-gray-400">Uploading...</p>
      )}
    </div>
  );
}

export default FileUpload; 