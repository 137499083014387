import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Helper function to format date for Supabase
export const formatDateForSupabase = (date) => {
  return date.toISOString();
};

// Helper function to parse date from Supabase
export const parseDateFromSupabase = (dateString) => {
  return new Date(dateString);
}; 