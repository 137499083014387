import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { supabase } from '../supabase/config';
import { Doughnut, Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/solid';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

function Investments() {
  const [investments, setInvestments] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedInvestment, setSelectedInvestment] = useState(null);
  const [monthlyChanges, setMonthlyChanges] = useState({});
  const [selectedProduct, setSelectedProduct] = useState('all');
  const [sharesData, setSharesData] = useState([]);
  const { user } = useAuth();

  const fetchMonthlyChanges = async () => {
    const currentDate = new Date();
    const currentMonth = currentDate.toISOString().slice(0, 7); // YYYY-MM
    
    const lastMonth = new Date(currentDate.setMonth(currentDate.getMonth() - 1))
      .toISOString().slice(0, 7);

    const { data: currentMonthData } = await supabase
      .from('investments_history')
      .select('*')
      .eq('month', currentMonth)
      .order('timestamp', { ascending: false })
      .limit(7); // One record per investment

    const { data: lastMonthData } = await supabase
      .from('investments_history')
      .select('*')
      .eq('month', lastMonth)
      .order('timestamp', { ascending: false })
      .limit(7);

    // Calculate changes
    const changes = {};
    currentMonthData?.forEach(current => {
      const lastMonth = lastMonthData?.find(last => 
        last.investment_name === current.investment_name
      );
      if (lastMonth) {
        const percentChange = ((current.amount - lastMonth.amount) / lastMonth.amount) * 100;
        changes[current.investment_name] = percentChange;
      }
    });

    return changes;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [{ data, error }, changes] = await Promise.all([
          supabase.from('investments').select('*').order('name'),
          fetchMonthlyChanges()
        ]);

        if (error) throw error;

        const investmentObj = {};
        data
          .sort((a, b) => b.amount - a.amount)
          .forEach(({ name, amount }) => {
            investmentObj[name] = amount;
          });

        setInvestments(investmentObj);
        setMonthlyChanges(changes);
      } catch (err) {
        console.error('Error:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    const fetchSharesData = async () => {
      try {
        let query = supabase
          .from('shares')
          .select('*')
          .order('date', { ascending: true });

        if (selectedProduct !== 'all') {
          query = query.eq('product_id', selectedProduct);
        }

        const { data, error } = await query;
        if (error) throw error;

        setSharesData(data || []);
      } catch (error) {
        console.error('Error fetching shares data:', error);
      }
    };

    if (user) {
      fetchSharesData();
    }
  }, [user, selectedProduct]);

  const total = Object.values(investments).reduce((sum, value) => sum + value, 0);
  
  const colors = {
    'Deputy Shares': '#4ade80',
    'Falak Vanguard': '#06b6d4',
    'Maryam Vanguard': '#a78bfa',
    'Village CCT': '#fbbf24',
    'Donovan Bvd': '#ef4444',
    'Gold': '#ec4899',
    'Other Saving': '#312e81'
  };

  const getChartData = () => {
    let labels = Object.keys(investments);
    let data = Object.values(investments);
    let backgroundColors = Object.values(colors);

    if (selectedInvestment) {
      const index = labels.indexOf(selectedInvestment);
      labels = [labels[index], 'Other'];
      const selectedAmount = data[index];
      const otherAmount = total - selectedAmount;
      data = [selectedAmount, otherAmount];
      backgroundColors = [colors[selectedInvestment], '#374151'];
    }

    return {
      labels,
      datasets: [{
        data,
        backgroundColor: backgroundColors,
        borderWidth: 0,
        borderRadius: 3,
      }]
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: '#1f2937',
        titleColor: '#e5e7eb',
        bodyColor: '#e5e7eb',
        borderColor: '#374151',
        borderWidth: 1,
        padding: 12,
        boxPadding: 6,
        callbacks: {
          label: (context) => {
            const value = context.raw;
            return `$${value.toLocaleString()}`;
          }
        }
      }
    },
    cutout: '75%'
  };

  const calculateMonthlyReturn = () => {
    if (!monthlyChanges || !total) return { amount: 0, percentage: 0 };
    
    // Calculate total monthly change
    const totalChange = Object.entries(investments).reduce((sum, [name, currentAmount]) => {
      const changePercent = monthlyChanges[name] || 0;
      const previousAmount = currentAmount / (1 + (changePercent / 100));
      return sum + (currentAmount - previousAmount);
    }, 0);

    return {
      amount: Math.round(totalChange),
      percentage: ((totalChange / (total - totalChange)) * 100).toFixed(1)
    };
  };

  const formatCurrency = (value) => {
    return `$${Math.round(value).toLocaleString()}`;
  };

  const handleCardClick = (name) => {
    setSelectedInvestment(selectedInvestment === name ? null : name);
  };

  const prepareSharesChartData = () => {
    if (!sharesData.length) return null;

    const sortedData = [...sharesData].sort((a, b) => 
      new Date(a.date) - new Date(b.date)
    );

    if (selectedProduct === 'all') {
      // Group by date and sum values and costs
      const aggregatedData = sortedData.reduce((acc, curr) => {
        const date = curr.date;
        if (!acc[date]) {
          acc[date] = { value: 0, cost: 0 };
        }
        acc[date].value += curr.value;
        acc[date].cost += curr.cost;
        return acc;
      }, {});

      const dates = Object.keys(aggregatedData).sort();
      
      return {
        labels: dates,
        datasets: [
          {
            label: 'Value',
            data: dates.map(date => ({
              x: date,
              y: aggregatedData[date].value
            })),
            borderColor: '#22c55e',
            backgroundColor: 'rgba(34, 197, 94, 0.1)',
            tension: 0.4,
            fill: true
          },
          {
            label: 'Cost',
            data: dates.map(date => ({
              x: date,
              y: aggregatedData[date].cost
            })),
            borderColor: '#f43f5e',
            backgroundColor: 'rgba(244, 63, 94, 0.1)',
            tension: 0.4,
            fill: true
          }
        ]
      };
    } else {
      return {
        labels: sortedData.map(d => d.date),
        datasets: [
          {
            label: 'Value',
            data: sortedData.map(d => ({
              x: d.date,
              y: d.value
            })),
            borderColor: '#22c55e',
            backgroundColor: 'rgba(34, 197, 94, 0.1)',
            tension: 0.4,
            fill: true
          },
          {
            label: 'Cost',
            data: sortedData.map(d => ({
              x: d.date,
              y: d.cost
            })),
            borderColor: '#f43f5e',
            backgroundColor: 'rgba(244, 63, 94, 0.1)',
            tension: 0.4,
            fill: true
          }
        ]
      };
    }
  };

  const prepareProfitChartData = () => {
    if (!sharesData.length) return null;

    const sortedData = [...sharesData].sort((a, b) => 
      new Date(a.date) - new Date(b.date)
    );

    // Get unique dates
    const dates = [...new Set(sortedData.map(d => d.date))].sort();

    // Calculate profits for each product by date
    const vdhgData = {};
    const vesgData = {};

    dates.forEach(date => {
      vdhgData[date] = 0;
      vesgData[date] = 0;
    });

    sortedData.forEach(d => {
      const profit = d.value - d.cost;
      if (d.product_id === 'VDHG') {
        vdhgData[d.date] = profit;
      } else if (d.product_id === 'VESG') {
        vesgData[d.date] = profit;
      }
    });

    return {
      labels: dates,
      datasets: [
        {
          label: 'VDHG',
          data: dates.map(date => vdhgData[date]),
          backgroundColor: '#06b6d4', // blue-800
          borderColor: '#06b6d4',
          borderWidth: 1,
          stack: 'stack0'
        },
        {
          label: 'VESG',
          data: dates.map(date => vesgData[date]),
          backgroundColor: '#a78bfa', // red-500
          borderColor: '#a78bfa',
          borderWidth: 1,
          stack: 'stack0'
        }
      ]
    };
  };

  const sharesChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month',
          displayFormats: {
            month: 'MMM yyyy'
          }
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: { 
          color: '#9CA3AF',
          maxRotation: 45,
          minRotation: 45
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: '#9CA3AF',
          callback: function(value) {
            return formatCurrency(value);
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#9CA3AF',
          usePointStyle: true,
          padding: 20,
          font: {
            size: 12
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(17, 24, 39, 0.8)',
        titleColor: '#F3F4F6',
        bodyColor: '#D1D5DB',
        padding: 12,
        borderColor: 'rgba(255, 255, 255, 0.1)',
        borderWidth: 1,
        callbacks: {
          title: function(context) {
            return new Date(context[0].raw.x).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          },
          label: function(context) {
            return `${context.dataset.label}: ${formatCurrency(context.raw.y)}`;
          }
        }
      }
    }
  };

  const profitChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month',
          displayFormats: {
            month: 'MMM-yy'
          }
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: { 
          color: '#9CA3AF',
          maxRotation: 45,
          minRotation: 45
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: '#9CA3AF',
          callback: function(value) {
            return formatCurrency(value);
          }
        },
        stacked: true
      }
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#9CA3AF',
          usePointStyle: true,
          padding: 20,
          font: {
            size: 12
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(17, 24, 39, 0.8)',
        titleColor: '#F3F4F6',
        bodyColor: '#D1D5DB',
        padding: 12,
        borderColor: 'rgba(255, 255, 255, 0.1)',
        borderWidth: 1,
        callbacks: {
          title: function(context) {
            return new Date(context[0].label).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short'
            });
          },
          label: function(context) {
            const value = context.raw;
            const total = context.chart.data.datasets.reduce((sum, dataset) => 
              sum + dataset.data[context.dataIndex], 0
            );
            
            const lines = [
              `${context.dataset.label}: ${formatCurrency(value)}`
            ];
            
            if (context.datasetIndex === context.chart.data.datasets.length - 1) {
              lines.push(`Total: ${formatCurrency(total)}`);
            }
            
            return lines;
          }
        }
      }
    }
  };

  // Add function to calculate shares stats
  const calculateSharesStats = () => {
    if (!sharesData.length) return null;

    // Get latest date for each product
    const latestByProduct = {};
    sharesData.forEach(item => {
      if (!latestByProduct[item.product_id] || 
          new Date(item.date) > new Date(latestByProduct[item.product_id].date)) {
        latestByProduct[item.product_id] = item;
      }
    });

    // Calculate latest totals
    const currentValue = Object.values(latestByProduct)
      .reduce((sum, item) => sum + item.value, 0);
    const totalCost = Object.values(latestByProduct)
      .reduce((sum, item) => sum + item.cost, 0);
    const totalProfit = currentValue - totalCost;
    const totalProfitPercentage = (totalProfit / totalCost) * 100;

    // Calculate product performance using latest values
    const productPerformance = Object.entries(latestByProduct).map(([product, data]) => ({
      id: product,
      value: data.value,
      cost: data.cost,
      profit: data.value - data.cost,
      return: ((data.value - data.cost) / data.cost) * 100
    }));

    // Find best performing product
    const bestProduct = productPerformance.reduce((best, current) => 
      current.return > (best?.return || -Infinity) ? current : best
    , null);

    // Calculate monthly change
    const latestDate = Math.max(...sharesData.map(d => new Date(d.date)));
    const oneMonthAgo = new Date(latestDate);
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    // Get values from one month ago
    const previousByProduct = {};
    sharesData.forEach(item => {
      const itemDate = new Date(item.date);
      if (itemDate <= oneMonthAgo && 
          (!previousByProduct[item.product_id] || 
           itemDate > new Date(previousByProduct[item.product_id].date))) {
        previousByProduct[item.product_id] = item;
      }
    });

    const previousTotalValue = Object.values(previousByProduct)
      .reduce((sum, item) => sum + item.value, 0);
    
    const monthlyChange = currentValue - previousTotalValue;
    const monthlyChangePercentage = (monthlyChange / previousTotalValue) * 100;

    return {
      totalValue: currentValue,
      totalCost,
      totalProfit,
      totalProfitPercentage,
      bestProduct,
      monthlyChange,
      monthlyChangePercentage,
      productPerformance
    };
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-[#111827] flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-[#111827] p-8">
        <div className="bg-red-900/30 border border-red-500 text-red-200 px-4 py-3 rounded-lg">
          Error: {error}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-navy-900 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Main Stats Cards */}
        <div className="mb-8 grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Total Portfolio Card */}
          <div className="bg-gray-900/50 rounded-2xl border border-navy-700/50 p-6 col-span-2">
            <h2 className="text-lg font-medium text-gray-200 mb-2">Total Portfolio Value</h2>
            <div className="text-4xl font-bold text-white mb-4">
              {formatCurrency(total)}
            </div>
            <div className={`flex items-center ${monthlyChanges['total'] >= 0 ? 'text-green-400' : 'text-red-400'}`}>
              {monthlyChanges['total'] >= 0 ? <ArrowUpIcon className="w-4 h-4 mr-1" /> : <ArrowDownIcon className="w-4 h-4 mr-1" />}
              <span className="text-sm">
                {monthlyChanges['total'] >= 0 ? '+' : ''}{monthlyChanges['total']?.toFixed(1)}% from last month
              </span>
            </div>
          </div>
          
          {/* Monthly Return Card */}
          <div className="bg-gray-900/50 rounded-2xl border border-navy-700/50 p-6">
            <h2 className="text-lg font-medium text-gray-200 mb-2">Monthly Return</h2>
            {(() => {
              const { amount, percentage } = calculateMonthlyReturn();
              return (
                <>
                  <div className="text-4xl font-bold text-white mb-4">
                    {formatCurrency(amount)}
                  </div>
                  <div className={`flex items-center ${percentage >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                    {percentage >= 0 ? <ArrowUpIcon className="w-4 h-4 mr-1" /> : <ArrowDownIcon className="w-4 h-4 mr-1" />}
                    <span className="text-sm">
                      {percentage >= 0 ? '+' : ''}{percentage}% from last month
                    </span>
                  </div>
                </>
              );
            })()}
          </div>
        </div>

        {/* Chart and Investments Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Donut Chart Card */}
          <div className="bg-gray-900/50 rounded-2xl border border-gray-800 p-6 lg:col-span-1">
            <h2 className="text-lg font-medium text-gray-200 mb-4">
              {selectedInvestment ? selectedInvestment : 'Portfolio Distribution'}
            </h2>
            <div className="relative h-[300px]">
              <Doughnut data={getChartData()} options={chartOptions} />
            </div>
          </div>

          {/* Investments List */}
          <div className="bg-gray-900/50 rounded-2xl border border-gray-800 p-6 lg:col-span-2">
            <h2 className="text-lg font-medium text-gray-200 mb-4">Investment Breakdown</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {Object.entries(investments).map(([name, value]) => (
                <div 
                  key={name} 
                  onClick={() => handleCardClick(name)}
                  className={`flex items-center p-4 rounded-xl border cursor-pointer transition-all duration-200 hover:scale-[1.02] ${
                    selectedInvestment === name 
                      ? 'bg-gray-800 border-purple-500' 
                      : 'bg-gray-800/50 border-gray-700 hover:bg-gray-800/80'
                  }`}
                >
                  <div className="mr-4">
                    <div 
                      className="w-12 h-12 rounded-lg flex items-center justify-center"
                      style={{ backgroundColor: `${colors[name]}20` }}
                    >
                      <div 
                        className="w-6 h-6 rounded-md"
                        style={{ backgroundColor: colors[name] }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-300 text-sm">{name}</div>
                    <div className="text-xl font-bold text-white">{formatCurrency(value)}</div>
                    <div className="flex items-center text-xs text-gray-500">
                      <span>{((value/total) * 100).toFixed(1)}% of portfolio</span>
                      {monthlyChanges[name] !== undefined && (
                        <span className={`ml-2 ${monthlyChanges[name] >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                          ({monthlyChanges[name] >= 0 ? '+' : ''}{monthlyChanges[name].toFixed(1)}%)
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Shares Section */}
        <div className="mt-8 space-y-6">
          {/* Shares Stats Cards */}
          {sharesData.length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {/* Current Portfolio Value */}
              <div className="bg-gray-900/50 rounded-2xl border border-navy-700/50 p-6">
                <h3 className="text-lg font-medium text-gray-200 mb-2">Current Portfolio Value</h3>
                <div className="text-3xl font-bold text-white mb-2">
                  {formatCurrency(calculateSharesStats()?.totalValue || 0)}
                </div>
                <div className={`flex items-center ${
                  calculateSharesStats()?.totalProfitPercentage >= 0 ? 'text-green-400' : 'text-red-400'
                }`}>
                  {calculateSharesStats()?.totalProfitPercentage >= 0 ? (
                    <ArrowUpIcon className="w-4 h-4 mr-1" />
                  ) : (
                    <ArrowDownIcon className="w-4 h-4 mr-1" />
                  )}
                  <span className="text-sm">
                    {calculateSharesStats()?.totalProfitPercentage?.toFixed(1)}% total return
                  </span>
                </div>
              </div>

              {/* Current Total Profit */}
              <div className="bg-gray-900/50 rounded-2xl border border-navy-700/50 p-6">
                <h3 className="text-lg font-medium text-gray-200 mb-2">Current Total Profit</h3>
                <div className="text-3xl font-bold text-white mb-2">
                  {formatCurrency(calculateSharesStats()?.totalProfit || 0)}
                </div>
                <div className="text-sm text-gray-400">
                  Cost basis: {formatCurrency(calculateSharesStats()?.totalCost || 0)}
                </div>
              </div>

              {/* Product Performance */}
              <div className="bg-gray-900/50 rounded-2xl border border-navy-700/50 p-6">
                <h3 className="text-lg font-medium text-gray-200 mb-2">Best Performer</h3>
                <div className="text-3xl font-bold text-white mb-2">
                  {calculateSharesStats()?.bestProduct?.id || '-'}
                </div>
                <div className="text-sm text-green-400">
                  {calculateSharesStats()?.bestProduct?.return?.toFixed(1)}% return
                </div>
                <div className="text-sm text-gray-400">
                  Profit: {formatCurrency(calculateSharesStats()?.bestProduct?.profit || 0)}
                </div>
              </div>

              {/* Monthly Change */}
              <div className="bg-gray-900/50 rounded-2xl border border-navy-700/50 p-6">
                <h3 className="text-lg font-medium text-gray-200 mb-2">30 Day Change</h3>
                <div className="text-3xl font-bold text-white mb-2">
                  {formatCurrency(calculateSharesStats()?.monthlyChange || 0)}
                </div>
                <div className={`flex items-center ${
                  calculateSharesStats()?.monthlyChangePercentage >= 0 ? 'text-green-400' : 'text-red-400'
                }`}>
                  {calculateSharesStats()?.monthlyChangePercentage >= 0 ? (
                    <ArrowUpIcon className="w-4 h-4 mr-1" />
                  ) : (
                    <ArrowDownIcon className="w-4 h-4 mr-1" />
                  )}
                  <span className="text-sm">
                    {calculateSharesStats()?.monthlyChangePercentage?.toFixed(1)}% from last month
                  </span>
                </div>
              </div>
            </div>
          )}

          {/* Value vs Cost Chart */}
          <div className="bg-gray-900/50 rounded-2xl border border-navy-700/50 p-6">
            <h2 className="text-2xl font-bold text-white mb-6">Shares Performance</h2>
            
            {/* Product Filter Chips */}
            <div className="flex flex-wrap gap-2 mb-6">
              <button
                onClick={() => setSelectedProduct('all')}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                  selectedProduct === 'all'
                    ? 'bg-purple-600'
                    : 'bg-navy-700 hover:bg-navy-600'
                }`}
              >
                All Products
              </button>
              <button
                onClick={() => setSelectedProduct('VESG')}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                  selectedProduct === 'VESG'
                    ? 'bg-purple-600'
                    : 'bg-navy-700 hover:bg-navy-600'
                }`}
              >
                VESG
              </button>
              <button
                onClick={() => setSelectedProduct('VDHG')}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                  selectedProduct === 'VDHG'
                    ? 'bg-purple-600'
                    : 'bg-navy-700 hover:bg-navy-600'
                }`}
              >
                VDHG
              </button>
            </div>

            {/* Value vs Cost Chart */}
            <div className="h-[400px]">
              {sharesData.length > 0 ? (
                <Line 
                  data={prepareSharesChartData()} 
                  options={sharesChartOptions}
                  id="shares-value-cost-chart"
                />
              ) : (
                <div className="flex items-center justify-center h-full text-gray-400">
                  No data available
                </div>
              )}
            </div>
          </div>

          {/* Profit Chart */}
          <div className="bg-gray-900/50 rounded-2xl border border-navy-700/50 p-6">
            <h2 className="text-2xl font-bold text-white mb-6">Profit Over Time by Share</h2>
            <div className="h-[400px]">
              {sharesData.length > 0 ? (
                <Bar 
                  data={prepareProfitChartData()} 
                  options={profitChartOptions}
                  id="shares-profit-chart"
                />
              ) : (
                <div className="flex items-center justify-center h-full text-gray-400">
                  No data available
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Investments; 
